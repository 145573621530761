import * as React from "react";
import CallToAction from "../components/CallToAction/CallToAction";
import Layout from "../components/Layout/Layout";
import Companies from "../components/Companies/Companies";
import Masthead from "../components/Masthead/Masthead";
import Service from "../components/Service/Service";
import TextMedia from "../components/TextMedia/TextMedia";
import MastImage from "../images/industries/manufacturing-header.jpg";
import Testimonial from "../components/Testimonial/Testimonial";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const ctaData = [
  {
    text: "Improving workflow for any department is essential. Keeping the plan clear, precise, and simple often leads to improved results and increases in efficiency.",
  },
  {
    text: "Identify, review, discuss and act all done by utilising the tools within T Cards Online. Helping you make the right decisions.",
  },
  {
    text: "Visibility and sharing information, giving you the most up to date information. Helping communication, whether between Manufacturing Cells or Customer Facing Staff.",
  },
  {
    text: "Creating data that can be easily reviewed. Accountability and traceability, leaving no stone unturned. Helping clients analyse their KPI's",
  },
];

const screensData = [
  {
    title: "Calendar or Custom Boards",
    text: "Keep track of Tasks using Customised boards built to your requirements by defining column headings. Easily adaptable as your needs change. Create and name Row Groups to help when allocating tasks to production cells or individuals. Calendar Boards are ideal for forward planning and analysis.",
    image: (
      <StaticImage
        src='../images/industries/manufacturing/board.jpg'
        alt='Calendar or Custom Boards'
      />
    ),
  },
  {
    title: "Unlimited Card Designs",
    text: "Card Designs can easily be created to suit any process using one of our design templates or simply create your own.",
    image: (
      <StaticImage
        src='../images/industries/manufacturing/card.jpg'
        alt='Unlimited Card Designs'
      />
    ),
  },
  {
    title: "Flexible User Permissions",
    text: "Change permissions to restrict the access or editing rights for all team members. Choose who can edit or view T Card Fields, Row Groups and T Boards.",
    image: (
      <StaticImage
        src='../images/industries/manufacturing/permissions.jpg'
        alt='Flexible User Permissions'
      />
    ),
  },
];

const quoteData = {
  text: "Managing different areas of the business with this clear and precise tool has been key to providing our clients with the most up to date information.",
  author:
    "Andy Bennett, S&S Global Demand Planning Lead, Meggitt Control Systems",
  image: <StaticImage src='../images/industries/meggitt-test.jpg' alt='' />,
};

const Title = () => {
  return (
    <div className='mb-5'>
      <h2 className='title italic'>
        A visual plan is an essential part of any successful department.
      </h2>
      <p>
        By having a visual overview of tasks using T Cards Online you create a
        clear and precise method of communication and co-ordination.
      </p>
      <p>
        T Cards Online is an ideal tool for managing jobs or tasks through
        manufacturing processes. But they are used for so much more. Easily
        adaptable for Managing Maintenance Tasks, Continuous Improvement, Safety
        Issues and Service calls. All of which can be tailored to your
        specification.
      </p>
    </div>
  );
};

const Manufacturing = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>T Cards Online | Agile Manufacturing & Engineering</title>
      </Helmet>
      <Masthead image={MastImage}>
        <h1>Agile Manufacturing & Engineering</h1>
      </Masthead>
      <CallToAction data={ctaData} sectionTitle={<Title />} />
      <Testimonial data={quoteData} />
      <TextMedia data={screensData} />

      <Service />
      <Companies title='Trusted across the industry' logos={data} />
    </Layout>
  );
};

export default Manufacturing;

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/images/logos/manufacturing/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(height: 115)
          }
        }
      }
    }
  }
`;
